declare global {
  interface Window {
    cookie: {
      getAttr(id: string): string;
    };
    currentAccount: {
      id: string;
    };
    config: {
      webNotificationsPublicChannel: string;
      webNotificationsMemberChannel: string;
      webNotificationsPubnubConf: PubNub.PubnubConfig;
    };
  }
}

// Export an empty object to satisfy the module system
export {};
